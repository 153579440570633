// stil a bloated code
// Suggestion: Create a Time Library if possible (or just fkin use luxon)
export default function (date, current = new Date(), precise = false) {
  // dates to be compared
  const pastDate = typeof date === 'string' ? new Date(date) : date;
  const currentDate = typeof current === 'string' ? new Date(current) : current;
  const pastDateMonth = pastDate.toLocaleString('default', { month: 'long' });
  const pastDateString = `${pastDateMonth} ${pastDate.getDate()}, ${pastDate.getFullYear()}`;
  // difference
  const year = currentDate.getFullYear() - pastDate.getFullYear();
  const month = currentDate.getMonth() - pastDate.getMonth();
  const day = currentDate.getDate() - pastDate.getDate();
  const hours = currentDate.getHours() - pastDate.getHours();
  const minutes = currentDate.getMinutes() - pastDate.getMinutes();
  const seconds = currentDate.getSeconds() - pastDate.getSeconds();
  // same time flags
  const sameYear = year === 0;
  const sameMonth = month === 0;
  const sameDay = day === 0;
  const sameHour = hours === 0;
  const sameMinutes = minutes === 0;
  const sameSeconds = seconds === 0;
  // day equiv
  const isToday = sameDay && sameMonth && sameYear;
  const isYesterday = day === 1 && sameMonth && sameYear;
  const datePassed = isToday
    ? 'Today'
    : isYesterday
    ? 'Yesterday'
    : pastDateString;

  // is present flag
  let isPresent = false;
  if (sameYear && sameMonth && sameDay && sameHour && sameMinutes) {
    isPresent = precise ? sameSeconds : true;
  }

  // seconds passed calculation
  let differenceSeconds = 0;
  differenceSeconds = year * (525600 * 60);
  differenceSeconds += month * (43800 * 60);
  differenceSeconds += day * 86400;
  differenceSeconds += hours * 3600;
  differenceSeconds += minutes * 60;
  differenceSeconds += seconds;

  // time passed in multiple formats
  const yearsPassed = Math.floor(differenceSeconds / 525600 / 60);
  const monthsPassed = Math.floor(differenceSeconds / 43800 / 60);
  const weeksPassed = Math.floor(differenceSeconds / 86400 / 7);
  const daysPassed = Math.floor(differenceSeconds / 86400);
  const hoursPassed = Math.floor(differenceSeconds / 3600);
  const minutesPassed = Math.floor(differenceSeconds / 60);
  const secondsPassed = differenceSeconds;
  const formats = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];
  const returnVal = {
    datePassed,
    isToday: sameDay && sameMonth && sameYear,
    isYesterday: day === 1 && sameMonth && sameYear,
    isPresent: isPresent,
    year: yearsPassed,
    month: monthsPassed,
    week: weeksPassed,
    day: daysPassed,
    hour: hoursPassed,
    minute: minutesPassed,
    second: secondsPassed,
    yearString: timePassedStringFormat(yearsPassed, 'year'),
    monthString: timePassedStringFormat(monthsPassed, 'month'),
    weekString: timePassedStringFormat(weeksPassed, 'week'),
    dayString: timePassedStringFormat(daysPassed, 'day'),
    hourString: timePassedStringFormat(hoursPassed, 'hour'),
    minuteString: timePassedStringFormat(minutesPassed, 'minute'),
    secondString: timePassedStringFormat(secondsPassed, 'second'),
  };
  // add a property of the nearest timePassed String
  for (let i = 0; i < formats.length; i++) {
    const format = formats[i];
    const time = returnVal[format];
    if (time > 0) {
      returnVal.timePassedString = timePassedStringFormat(time, format);
      break;
    }
  }

  return returnVal;
}

function timePassedStringFormat(time, format) {
  const singular = time === 'hour' ? 'An' : 'A';
  return `${time > 1 ? time : singular} ${format}${time > 1 ? 's' : ''} ago`;
}
