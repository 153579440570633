// to be used
import axios from 'axios';
import { URL_PREFIX } from './variables';

export const getNotifications = (...rest) =>
  axios.get(`${URL_PREFIX}/notification`, ...rest);

export const readNotifications = (...rest) =>
  axios.post(`${URL_PREFIX}/notification`, ...rest);

export default {
  getNotifications,
  readNotifications,
};
